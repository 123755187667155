<!--
 * @Description: 泊位全景 人行道  inCharge
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 14:32:31
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <van-nav-bar title="泊位全景"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft" />
    <van-row>
      <van-search v-model="plateNumber"
                  show-action
                  placeholder="请输入车牌号">
        <template #action>
          <div @click="getAllBerthage">搜索</div>
        </template>
      </van-search>
    </van-row>
    <div class="tree-box"
         v-if="searchShow===true"
         ref="wrapperBoxIn">
      <van-col :span="12"
               class="card"
               v-for="(item,index) in onlinelist"
               :key="index">
        <van-col :span="12"
                 v-if="item.numberPlateColorCode===2"
                 class="bluePlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col :span="12"
                 v-if="item.numberPlateColorCode===6"
                 class="greenPlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col :span="12"
                 v-if="item.numberPlateColorCode===3"
                 class="yellowPlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col :span="12"
                 v-if="item.numberPlateColorCode!==2&&item.numberPlateColorCode!==3&&item.numberPlateColorCode!==6"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
      </van-col>
    </div>
    <div class="tree-box"
         v-if="searchShow===false"
         ref="wrapperBoxIn">
      <van-col :span=12
               class="card">
        <van-col @click="register()"
                 class="register">
          <van-icon name="plus"
                    size="50px"
                    color="#f0f0f0" />
        </van-col>
      </van-col>
      <van-col :span=12
               class="card"
               v-for="(item,index) in  onlinelist"
               :key="index">
        <van-col :span=12
                 v-if="item.numberPlateColorCode===2"
                 class="bluePlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col :span=12
                 v-if="item.numberPlateColorCode===6"
                 class="greenPlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col :span=12
                 v-if="item.numberPlateColorCode===3"
                 class="yellowPlate"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
        <van-col v-if="item.numberPlateColorCode!==2&&item.numberPlateColorCode!==3&&item.numberPlateColorCode!==6"
                 @click="handleClickPlateNumber(item)">
          {{item.plateNumber}}
        </van-col>
      </van-col>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      timedTask: null, // 定时任务
      scrollTop: 0,
      berthageList: [], // 全部泊位列表
      onlinelist: [], // 在线订单页面
      // 查询表单
      queryForm: {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId
        // plateNumber: ''
      },
      plateNumber: '',
      list: [],
      searchBerthageList: [],
      searchShow: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    plateNumber () {
      if (this.plateNumber.length === 0) {
        this.getAllBerthage()
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getAllBerthage()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.timedTask = setInterval(() => {
      this.getAllBerthage()
    }, 180000)
    // 获取滑动位置
    this.$refs.wrapperBoxIn.addEventListener('scroll', () => {
      this.scrollTop = this.$refs.wrapperBoxIn.scrollTop
    }, false)
  },
  updated () { // 获取当前上次打开的位置
    // 获取当前上次打开的位置
    this.$nextTick(() => {
      this.$refs.wrapperBoxIn.scrollTop = Number(localStorage.getItem('indexscTop'))
    })
  }, // 生命周期 - 更新之后
  beforeDestroy () {
    // 页面销毁时设置位置
    localStorage.setItem('indexscTop', this.scrollTop)
  }, // 生命周期 - 销毁之前
  destroyed () {
    if (this.timedTask) {
      clearTimeout(this.timedTask)
      this.timedTask = null
    }
  }, // 生命周期 - 销毁完成
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 登记按钮
    register () {
      this.$router.push({
        name: 'register',
        query: { page: 'in' }
      })
    },
    // 点击车牌号
    handleClickPlateNumber (item) {
      if (item.entranceTime) {
        this.$router.push({
          name: 'orderDetails',
          query: { orderDetails: JSON.stringify(item) }
        })
      }
    },
    // 泊位选择器筛选
    berthageInput (val) {
      this.searchBerthageList = this.berthageList.filter((item) => {
        return item.parkSpaceNumber.toLowerCase().includes(val.toLowerCase())
      })
    },
    // 获取全部泊位
    getAllBerthage () {
      this.berthageList = []
      this.onlinelist = []
      const info = {
        columnName: ['park_space_id', 'park_space_id'],
        tableName: 'tb_park_space',
        whereStr: [
          {
            colName: 'park_id',
            value: this.$cookie.get('managePark').manageParkId
          },
          {
            colName: 'park_layer_id',
            value: this.$cookie.get('manageParkLayer').manageParkLayerId
          }
        ]
      }
      const info1 = {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId,
        plateNumber: this.queryForm.plateNumber
      }
      if (this.plateNumber) {
        this.searchShow = true
        this.$outCharge.getOrderOnlineNoPage(info1).then(res => {
          res.resultEntity.forEach(item => {
            if (item.plateNumber === this.plateNumber) {
              this.onlinelist.push(item)
            }
          })
        })
      } else {
        this.searchShow = false
        this.$queryDict.queryDict(info).then(res => {
          this.berthageList = res.resultEntity
          this.berthageList.forEach(item => {
            item.parkSpaceNumber = item.code
          })
          this.$outCharge.getOrderOnlineNoPage(info1).then(response => {
            this.onlinelist = response.resultEntity
            for (let j = 0; j < this.onlinelist.length; j++) {
              for (let i = 0; i < this.berthageList.length; i++) {
                if (this.berthageList[i].parkSpaceNumber === this.onlinelist[j].parkSpaceNumber) {
                  this.berthageList[i] = this.onlinelist[j]
                }
              }
            }
            this.berthageList = [...this.berthageList]
          })
        })
      }
    },
    // 顶部返回按钮
    onClickLeft () {
      this.$router.push('/homePage')
    }
  }
}
</script>
<style lang='less' scoped>
// @import url(); 引入公共css类
.bluePlate {
  width: 100%;
  height: 100%;
  background: url('~@/assets/plateNumber/blue.png');
  background-repeat: no-repeat;
  background-size: 100% 106%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 23px;
}
.greenPlate {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/plateNumber/green.png');
  background-repeat: no-repeat;
  background-size: 100% 106%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 23px;
}
.yellowPlate {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/plateNumber/yellow.png');
  background-repeat: no-repeat;
  background-size: 100% 106%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 23px;
}
.mainBody {
  height: 100%;
  width: 100%;
  .tree-box {
    height: calc(100vh - @navHeight - 54px);
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: auto;
  }
  .card {
    height: 90px;
    margin-top: 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .register {
      width: 90%;
      height: 90%;
      display: flex;
      background-color: #ffffff;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
